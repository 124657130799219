import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import PopperMenu from '@lobox/uikit/PopperMenu';
import AvatarMenu from './TopBarAvatar.menu';
import classes from './TopBarAvatar.component.module.scss';

export interface TopBarAvatarProps {
  avatarSrc: string;
  userLink: string;
  fullName: string;
  occupation: string;
  usernameAtSign: string;
}

const TopBarAvatar: React.FC<TopBarAvatarProps> = ({
  avatarSrc,
  userLink,
  fullName,
  occupation,
  usernameAtSign,
}) => {
  return (
    <PopperMenu
      closeOnScroll
      hasArrow
      offsetY={10}
      placement="bottom-end"
      buttonComponent={
        <Avatar className={classes.avatarButton} size="sm" imgSrc={avatarSrc} />
      }
      menuClassName={classes.menuClassName}
    >
      <AvatarMenu
        imgSrc={avatarSrc}
        profileLink={userLink}
        exactName={fullName}
        job={occupation}
        usernameAtSign={usernameAtSign}
      />
    </PopperMenu>
  );
};

export default TopBarAvatar;
