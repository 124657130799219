import React from 'react';
import SearchItemSkeleton from '@lobox/uikit/Skeleton/Skeleton.SearchItem';
import cnj from '@lobox/uikit/utils/cnj';
import classes from './DesktopSearchInput.skeleton.module.scss';

const DesktopSearchInputSkeleton: React.FC<{ isDrawer?: boolean }> = ({
  isDrawer,
}) => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((i) => (
        <SearchItemSkeleton
          avatarSize={42}
          key={`${i}_ts_sk`}
          short
          className={cnj(
            classes.skeletonItem,
            isDrawer && classes.skeletonItem_drawer
          )}
        />
      ))}
    </>
  );
};

export default DesktopSearchInputSkeleton;
