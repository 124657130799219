export const INITIAL_GET_EMAIL = 'INITIAL_GET_EMAIL';
export const INITIAL_GET_NAME = 'INITIAL_GET_NAME';
export const INITIAL_GET_COUNTRY = 'INITIAL_GET_COUNTRY';
export const IS_OAUTH_FLOW = 'IS_OAUTH_FLOW';
export const INVITED_BY_KEY = '_invb';
export const LANDING = 'landing';

export const ContentHeight = {
  mobile: 84,
  tablet: 108,
  desktop: 144,
};
export const QueryKeys = {
  allUsers: 'allUsers',
  peoples: 'peoples',
  pages: 'pages',
};
