'use client';

import React, { useRef } from 'react';
import Flex from '@lobox/uikit/Flex';
import ScrollView from '@lobox/uikit/ScrollView';
import Spinner from '@lobox/uikit/Spinner';
import dynamic from 'next/dynamic';
import type { ScrollViewElement } from '@lobox/utils';
import Header from './LandingLayout.Header';
import classes from './LandingLayout.component.module.scss';
import cnj from '@lobox/uikit/utils/cnj';
import { LANDING } from '../../../constants/enums';

const Footer = dynamic(() => import('./LandingLayout.Footer'), {
  ssr: false,
  loading: () => <Spinner />,
});

interface AppLayoutProps {
  children?: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const scrollRef = useRef<ScrollViewElement>(null);

  return (
    <ScrollView
      id="landingSearchResult"
      className={cnj(classes.main, LANDING)}
      ref={scrollRef}
    >
      <Flex className={classes.top}>
        <Header />
      </Flex>
      <Flex className={classes.content}>{children}</Flex>
      <Flex className={classes.bottom}>
        <Footer />
      </Flex>
    </ScrollView>
  );
};

export default AppLayout;
