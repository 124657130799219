import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import classes from './TopBarAvatar.userInfo.module.scss';

interface TopBarAvatarUserInfoProps {
  isAvatarInCenter?: boolean;
  avatarSize?: any;
  exactName?: string;
  job?: string;
  usernameAtSign?: string;
  profileLink: string;
  imgSrc?: string;
}

const TopBarAvatarUserInfo: React.FC<TopBarAvatarUserInfoProps> = ({
  isAvatarInCenter,
  avatarSize,
  exactName,
  job,
  usernameAtSign,
  profileLink,
  imgSrc,
}) => {
  return (
    <Flex>
      <BaseButton
        href={profileLink}
        className={cnj(
          classes.avatarWrapper,
          isAvatarInCenter && classes.avatarWrapper_center
        )}
      >
        <Avatar size={avatarSize} imgSrc={imgSrc} />
      </BaseButton>
      <Flex
        className={cnj(
          classes.userInfo,
          isAvatarInCenter && classes.userInfo_center
        )}
      >
        <BaseButton href={profileLink}>
          <Typography
            color="thirdText"
            size={20}
            height={23.44}
            font="700"
            isWordWrap
          >
            {exactName}
          </Typography>
        </BaseButton>
        <BaseButton href={profileLink}>
          <Typography
            color="forthText"
            size={15}
            font="400"
            height={21}
            isWordWrap
          >
            {usernameAtSign}
          </Typography>
        </BaseButton>
        <Typography
          color="primaryText"
          size={15}
          font="400"
          height={24}
          className={classes.job}
          isWordWrap
        >
          {job}
        </Typography>
      </Flex>
    </Flex>
  );
};

export default TopBarAvatarUserInfo;
