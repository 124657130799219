import React from 'react';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import Form from '@lobox/uikit/Form';
import {
  useTranslation,
  Endpoints,
  getCookieKey,
  Cookies,
  useLogout,
} from '@lobox/utils';
import type { ButtonProps } from '@lobox/uikit/Button';
import urls from '../../../../shared/constants/urls';

interface LogoutProps {
  variant?: ButtonProps['variant'];
  className?: string;
  isDrawer?: boolean;
}

const Logout: React.FC<LogoutProps> = ({ variant, className }) => {
  const { t } = useTranslation();
  const logoutHandler = useLogout();
  const DEVICE_ID = getCookieKey('deviceId');
  const deviceId = Cookies.get(DEVICE_ID);

  const handleLogout = () => logoutHandler(urls.login);

  return (
    <Form
      initialValues={{ deviceId }}
      onSuccess={handleLogout}
      onFailure={handleLogout}
      url={Endpoints.Auth.logout}
      method="DELETE"
    >
      {() => (
        <SubmitButton
          active
          schema="semi-transparent"
          rightIcon="sign-out-alt"
          label={t('logout_cap')}
          variant={variant}
          className={className}
        />
      )}
    </Form>
  );
};

export default Logout;
