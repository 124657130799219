import classes from './Skeleton.SearchItem.module.scss';

import React from 'react';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import Box from '../Layout/Box';
import Skeleton from './Skeleton';

export interface SkeletonSearchItemProps {
  className?: string;
  short?: boolean;
}

const SkeletonSearchItem: React.FC<SkeletonSearchItemProps> = ({
  className,
  short,
}) => {
  return (
    <Box className={cnj(classes.skeletonSearchItemRoot, className)}>
      <Flex className={classes.avatarWrapper}>
        <Skeleton className={classes.skeleton__img} />
      </Flex>
      <Flex className={classes.body}>
        <Skeleton
          className={cnj(
            classes.skeleton__1,
            short && classes.skeleton__1_short
          )}
        />
        <Skeleton className={classes.skeleton__2} />
        {!short && <Skeleton className={classes.skeleton__3} />}
      </Flex>
    </Box>
  );
};

export default SkeletonSearchItem;
