import { routeNames, urlsBuilder, appEnvironment } from '@lobox/utils';

const {
  baseUrl: productionBaseUrl,
  storageBaseUrl,
  apiBaseUrl,
  port,
} = appEnvironment;

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? `http://localhost:${port}`
    : productionBaseUrl;
const commonUrls = urlsBuilder({ baseUrl, storageBaseUrl, apiBaseUrl });

const urls = {
  base: `${baseUrl}`,
  home: `${baseUrl}${routeNames.home}`,
  login: commonUrls.login,
  signup: commonUrls.signUp,
  getName: commonUrls.getName,
  languagesAddress: commonUrls.languagesAddress,
  socialAuth: commonUrls.socialAuth,
  twoFactorAuthGetCode: commonUrls.twoFactorAuthGetCode,
  userProfile: (username: string): string => `${baseUrl}/${username}`,
};

export default urls;
