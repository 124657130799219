import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useHistory } from '@lobox/utils';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import ScrollView from '@lobox/uikit/ScrollView';
import SearchInput from '@lobox/uikit/SearchInput';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import {
  encoder,
  useOutsideListener,
  useStateCallback,
  useTranslation,
  useUrlQuery,
} from '@lobox/utils';
import useSearchTopBar from '@shared/hooks/useSearchTopBar';
import debounce from 'lodash/debounce';
import { landingRouteNames as routeNames } from '@lobox/utils';
import DesktopSearchEmpty from '../../../../shared/components/organism/DesktopSearchInput/DesktopSearchInput.empty';
import DesktopSearchInputSkeleton from './DesktopSearchInput.skeleton';
import DesktopSearchInputResultItem from './DesktopSearchInput.resultItem';
import DesktopSearchInputShowAll from './DesktopSearchInput.showAll';
import classes from './DesktopSearchInput.component.module.scss';

interface DesktopSearchInputProps {
  openDrawer?: any;
  closeDrawer?: any;
  isDrawerOpen?: boolean;
  searchText: string;
  setText: (...args: any) => any;
}

const MOBILE_SEARCH_INPUT = 'mobile-search-input';

const DesktopSearchInput = (
  {
    openDrawer,
    closeDrawer,
    isDrawerOpen,
    searchText,
    setText,
  }: DesktopSearchInputProps,
  ref: any
) => {
  const containerRef = useRef<any>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const query = useUrlQuery();
  const searchQuery = decodeURIComponent(query.get('query' || '') || '');
  const [selectedItemText] = useState<string | null>();
  const [showAutoComplete, setAutoComplete] = useStateCallback(false);
  const { objects, handleSearch, isLoading } = useSearchTopBar();

  useEffect(() => {
    if (isDrawerOpen) {
      document.getElementById(MOBILE_SEARCH_INPUT)?.focus();
    }
  }, [isDrawerOpen]);

  const handleGetSearchText = debounce(setText, 750);

  useOutsideListener(containerRef?.current, () => setAutoComplete(false));

  const onKeyDown = (e: any) => {
    const val = e.target.value;
    if (e.key === 'Enter' && val) {
      history.push(`${routeNames.explorePeople}?query=${encoder(val)}`);
      setAutoComplete(false);
      closeDrawer();
    }
  };

  const searchInputProps = {
    ref,
    placeholder: t('search_placeholder'),
    inputProps: {
      autoComplete: 'off',
      onKeyDown,
    },
    defaultValue: searchQuery as string,
    onChange: (val: string) => {
      handleGetSearchText(val);
      handleSearch(val);
    },
    onClearSearch: closeDrawer,
    onFocus: () => {
      setAutoComplete(true);
    },
  };

  return (
    <>
      <IconButton
        colorSchema="transparent"
        name="search"
        type="fas"
        size="lg"
        className={classes.searchIconButton}
        onClick={openDrawer}
      />
      <Flex className={classes.desktopSearchInput} ref={containerRef}>
        <SearchInput
          {...searchInputProps}
          className={classes.input}
          key={selectedItemText}
        />
        <Flex
          className={cnj(
            classes.autoComplete,
            showAutoComplete && classes.autoComplete_showAutoComplete
          )}
        >
          <DesktopSearchEmpty
            searchText={searchText}
            isEmpty={objects?.length === 0}
            isLoading={isLoading}
          />
          {isLoading ? (
            <DesktopSearchInputSkeleton />
          ) : (
            <DesktopSearchInputResultItem objects={objects.slice(0, 5)} />
          )}
          {!isLoading && searchText && (
            <DesktopSearchInputShowAll
              searchText={searchText}
              setAutoComplete={setAutoComplete}
              closeDrawer={closeDrawer}
            />
          )}
        </Flex>
      </Flex>
      {isDrawerOpen && (
        <Flex className={classes.modalRoot}>
          <Flex className={classes.searchPopUpWrapper}>
            <Flex className={classes.header}>
              <IconButton
                colorSchema="transparent"
                name="chevron-left"
                type="far"
                onClick={closeDrawer}
                className={classes.backButton}
              />
              <Typography size={20} height={24} font="700" color="coal">
                {t('search')}
              </Typography>
            </Flex>
            <SearchInput
              {...searchInputProps}
              inputId={MOBILE_SEARCH_INPUT}
              className={classes.searchInput}
              key={`${selectedItemText}_drawer`}
              variant="circle"
            />
            <ScrollView>
              <Flex className={classes.mobileSearchInput} ref={containerRef}>
                <DesktopSearchEmpty
                  searchText={searchText}
                  isEmpty={objects?.length === 0}
                  isLoading={isLoading}
                />
                {isLoading ? (
                  <DesktopSearchInputSkeleton isDrawer />
                ) : (
                  <DesktopSearchInputResultItem objects={objects} />
                )}
                {!isLoading && searchText && (
                  <DesktopSearchInputShowAll
                    searchText={searchText}
                    setAutoComplete={setAutoComplete}
                    closeDrawer={closeDrawer}
                  />
                )}
              </Flex>
            </ScrollView>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default forwardRef<any, DesktopSearchInputProps>(DesktopSearchInput);
