import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import Follow from '../../../shared/components/molecules/Follow';
import { useTranslation, urls } from '@lobox/utils';
import classes from './SearchItem.module.scss';

export interface SearchItemProps {
  name: string;
  job?: string;
  username: string;
  followers?: number | string;
  following?: number | string;
  avatar?: any;
  className?: string;
  noDivider?: boolean;
  dontShowUsername?: boolean;
  onClick?: () => void;
  isCompany: boolean;
}

const SearchItem: React.FC<SearchItemProps> = ({
  name,
  job,
  username,
  followers,
  following,
  avatar,
  className,
  noDivider,
  dontShowUsername,
  isCompany,
}) => {
  const { t } = useTranslation();

  return (
    <a href={`${urls.base}/${username}`}>
      <BaseButton
        className={cnj(
          classes.searchItemRoot,
          noDivider && classes.searchItemRoot_noDivider,
          className
        )}
      >
        <Avatar isCompany={isCompany} imgSrc={avatar} size="md" />
        <Flex className={classes.infoWrapper}>
          <Flex className={classes.nameWrapper}>
            <Typography
              font="700"
              size={18}
              height={22.5}
              color="coal"
              className={classes.nameOfUser}
              isTruncated
            >
              {name}
            </Typography>
            {!dontShowUsername && (
              <>
                <DividerVertical height={18} className={classes.divider} />
                <Typography
                  font="400"
                  size={15}
                  height={21}
                  color="gray"
                  isTruncated
                >
                  {username}
                </Typography>
              </>
            )}
          </Flex>
          {!!job && (
            <Typography
              font="400"
              size={15}
              height={21}
              color="graphene"
              className={classes.job}
              isTruncated
            >
              {job}
            </Typography>
          )}
          {followers && following && (
            <Flex className={classes.followWrapper}>
              <Follow label={t('followers_cap')} number={followers} />
              <DividerVertical height={14} />
              <Follow label={t('following_cap')} number={following} />
            </Flex>
          )}
        </Flex>
      </BaseButton>
    </a>
  );
};

export default SearchItem;
