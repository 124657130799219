import React from 'react';
import SearchItem from '@/app/(landing)/shared/components/molecules/SearchItem';
import { APP_ENTITIES } from '@lobox/utils';

const DesktopSearchInputResultItem: React.FC<{
  objects: Array<any>;
}> = ({ objects }) => (
  <>
    {objects?.map((item: any) => {
      const {
        id,
        fullName,
        subTitle,
        croppedImageUrl,
        username,
        userType,
        visibleProfilePhoto,
        visibleJobTitle,
      } = item;
      return (
        <SearchItem
          noDivider
          key={`search_item_${id}`}
          name={fullName}
          username={username}
          job={visibleJobTitle === false ? `@${username}` : subTitle}
          avatar={visibleProfilePhoto === false ? undefined : croppedImageUrl}
          dontShowUsername
          isCompany={userType === APP_ENTITIES.page}
        />
      );
    })}
  </>
);

export default DesktopSearchInputResultItem;
