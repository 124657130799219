import React from 'react';
import Button from '@lobox/uikit/Button';
import Divider from '@lobox/uikit/Divider';
import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import Flex from '@lobox/uikit/Flex';
import {
  encoder,
  useTranslation,
  landingRouteNames as routeNames,
  useHistory,
  useLocation,
} from '@lobox/utils';
import classes from './DesktopSearchInput.showAll.module.scss';

interface DesktopSearchInputShowAllProps {
  searchText: string;
  setAutoComplete: (...args: any) => void;
  closeDrawer: () => void;
}
const DesktopSearchInputShowAll = ({
  searchText,
  setAutoComplete,
  closeDrawer,
}: DesktopSearchInputShowAllProps) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const doesShowAllResults = pathname !== routeNames.explore;

  return (
    <>
      <Flex
        as="span"
        className={classes.onlySearchWrapper}
        onClick={() =>
          setAutoComplete(false, () => {
            closeDrawer();
            push(`${routeNames.explorePeople}?query=${encoder(searchText)}`);
          })
        }
      >
        <Icon
          name="search"
          size={18}
          type="fas"
          className={classes.onlySearchIcon}
        />
        <Typography font="700" size={18} height={22.5} color="coal">
          {searchText}
        </Typography>
      </Flex>
      {doesShowAllResults && (
        <>
          <Divider className={classes.divider} />
          <Button
            className={classes.seeAllButton}
            labelClassName={classes.seeAllButtonLabel}
            schema="transparent"
            label={t('see_all_results')}
            onClick={() => {
              setAutoComplete(false, () => {
                push(
                  `${routeNames.explorePeople}?query=${encoder(searchText)}`
                );
                closeDrawer();
              });
            }}
          />
        </>
      )}
    </>
  );
};

export default DesktopSearchInputShowAll;
