import { useEffect } from 'react';
import {
  QueryKeys,
  suggestHashtags,
  suggestObjects,
  useDebounceState,
  useInfiniteQuery,
} from '@lobox/utils';
import type {
  ISuggestObject,
  ISuggestHashtag,
  PaginateResponse,
} from '@lobox/utils';

type UseSearchTopBarProps = {
  inputValue: string;
  handleSearch: (arg: string) => void;
  objects: Array<any>;
  hashtags: Array<any>;
  isLoading: boolean;
};

const useSearchTopBar = (): UseSearchTopBarProps => {
  const { setValue, debounceValue } = useDebounceState('', 500);
  const handleSearch = (val: any) => {
    setValue(val);
  };

  const {
    isLoading: objectIsLoading,
    refetch: refetchObjects,
    data: objectData,
  } = useInfiniteQuery<PaginateResponse<ISuggestObject>>(
    [QueryKeys.suggestObjects, debounceValue],
    {
      func: suggestObjects,
      size: 10,
      extraProps: {
        text: debounceValue,
      },
    },
    {
      enabled: false,
    }
  );

  const {
    isLoading: hashtagIsLoading,
    refetch: refetchHashtags,
    data: hashtagData,
  } = useInfiniteQuery<PaginateResponse<ISuggestHashtag>>(
    [QueryKeys.suggestHashtags, debounceValue],
    {
      func: suggestHashtags,
      size: 10,
      extraProps: {
        text: debounceValue,
      },
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (debounceValue) {
      if (!debounceValue.startsWith('#')) {
        refetchObjects();
      }
      refetchHashtags();
    }
  }, [debounceValue, refetchHashtags, refetchObjects]);

  return {
    isLoading: objectIsLoading || hashtagIsLoading,
    inputValue: debounceValue,
    handleSearch,
    objects: objectData || [],
    hashtags: hashtagData || [],
  };
};

export default useSearchTopBar;
