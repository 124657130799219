import React from 'react';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import { useTranslation } from '@lobox/utils';
import Logout from './TopBarAvatar.logout';
import UserInfo from './TopBarAvatar.userInfo';
import classes from './TopBarAvatar.menu.module.scss';

interface TopBarAvatarMenuProps {
  exactName?: string;
  job?: string;
  usernameAtSign?: string;
  profileLink: string;
  imgSrc?: string;
  originalImg?: string;
}

const TopBarAvatarMenu: React.FC<TopBarAvatarMenuProps> = ({
  exactName,
  job,
  usernameAtSign,
  profileLink,
  imgSrc,
  originalImg,
}) => {
  const { t } = useTranslation();

  return (
    <Flex className={classes.avatarMenuRoot}>
      <UserInfo
        {...{
          isAvatarInCenter: true,
          avatarSize: 'lg',
          exactName,
          job,
          usernameAtSign,
          profileLink,
          imgSrc,
          originalImg,
        }}
      />
      <Button
        href={profileLink}
        className={classes.viewProfile}
        label={t('view_profile')}
        schema="primary-blue"
      />
      <Logout />
    </Flex>
  );
};

export default TopBarAvatarMenu;
