import React, { useRef } from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Link from '@lobox/uikit/Link';
import SearchShape from '@lobox/uikit/SearchInput';
import DesktopSearchInput from '../../../../shared/components/organism/DesktopSearchInput';
import {
  useAuthState,
  useLocation,
  useStateCallback,
  useTranslation,
  landingRouteNames,
} from '@lobox/utils';
import Logo from '@shared/svg/landing/LogoIcon';
import classes from './LandingLayout.Header.module.scss';
import LandingTopBarAvatar from './LandingLayout.TopBarAvatar';

interface HeaderProps {
  className?: string;
}

const Header = ({ className }: HeaderProps): JSX.Element => {
  const isLoggedIn = useAuthState('isLoggedIn');
  const searchInputRef = useRef<any>(null);
  const [searchText, setText] = useStateCallback('');
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const isItOnPrimePage = pathname === landingRouteNames.prime;
  const [isDrawerOpen, setDrawerSituation] = useStateCallback<boolean>(false);
  const signUpSchema = !isItOnPrimePage ? 'primary-blue' : 'ghost';
  const loginSchema = isItOnPrimePage ? 'primary-blue' : 'ghost';

  const closeDrawer = () => setDrawerSituation(false);
  const openDrawer = () => {
    setDrawerSituation(true);
  };

  return (
    <>
      <Flex className={cnj(classes.headerRoot, className)}>
        <Flex
          className={cnj(
            classes.buttonsWrapper,
            isItOnPrimePage && classes.buttonsWrapper_primePage
          )}
        >
          <Link to={landingRouteNames.prime} className={classes.logoButton}>
            <Logo visiblelobox className={classes.logo} />
          </Link>
        </Flex>
        <DesktopSearchInput
          ref={searchInputRef}
          openDrawer={openDrawer}
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
          setText={setText}
          searchText={searchText as string}
        />
        <Flex
          className={cnj(
            classes.buttonsWrapper,
            isItOnPrimePage && classes.buttonsWrapper_primePage
          )}
        >
          {isLoggedIn ? (
            <LandingTopBarAvatar />
          ) : (
            <>
              <Link to={landingRouteNames.signup}>
                <Button
                  key={signUpSchema}
                  schema={signUpSchema}
                  label={t('signup_cap')}
                  className={cnj(
                    classes.authButtons,
                    !isItOnPrimePage && classes.gap
                  )}
                  labelClassName={classes.authButtonText}
                />
              </Link>
              <Link to={landingRouteNames.login}>
                <Button
                  key={loginSchema}
                  schema={loginSchema}
                  label={t('login_cap')}
                  className={cnj(
                    classes.authButtons,
                    classes.marginLeft,
                    isItOnPrimePage && classes.gap
                  )}
                  labelClassName={classes.authButtonText}
                />
              </Link>
            </>
          )}
        </Flex>
      </Flex>
      <SearchShape
        placeholder={t('search_placeholder')}
        className={classes.smallMobileSearch}
        onFocus={openDrawer}
      />
    </>
  );
};

export default Header;
