import React from 'react';
import { useAuthUser } from '@lobox/utils';
import TopBarAvatar from '../../../../shared/components/organism/TopBarAvatar';

const LandingTopBarAvatar = (): JSX.Element => {
  const { data: user } = useAuthUser();

  return (
    <TopBarAvatar
      avatarSrc={user.croppedImageUrl}
      userLink={user.userLink}
      fullName={user.fullName}
      occupation={user.occupation?.label}
      usernameAtSign={user.usernameAtSign}
    />
  );
};

export default LandingTopBarAvatar;
