import React from 'react';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import classes from './DesktopSearchInput.empty.module.scss';

interface DesktopSearchInputProps {
  isEmpty?: boolean;
  isLoading?: boolean;
  searchText: string;
}

const DesktopSearchEmpty = ({
  isLoading,
  isEmpty,
  searchText,
}: DesktopSearchInputProps) => {
  const { t } = useTranslation();

  return (
    <>
      {!isLoading && isEmpty && !searchText && (
        <Typography
          font="400"
          size={15}
          height={21}
          color="secondaryDisabledText"
          mr="auto"
          ml={8}
          className={classes.empty}
        >
          {t('no_recent_searches')}
        </Typography>
      )}
      {!isLoading && isEmpty && !!searchText && (
        <Typography
          font="400"
          size={15}
          height={21}
          color="secondaryDisabledText"
          mr="auto"
          ml={8}
          className={classes.empty}
        >
          {t('empty_msg')}
        </Typography>
      )}
    </>
  );
};

export default DesktopSearchEmpty;
