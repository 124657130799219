import React from 'react';
import Typography from '@lobox/uikit/Typography';
import Flex from '@lobox/uikit/Flex';
import classes from './Follow.module.scss';

interface FollowProps {
  label: string;
  number: number | string;
}

const Follow: React.FC<FollowProps> = ({ label, number }) => {
  return (
    <Flex className={classes.followRoot}>
      <Typography font="700" size={15} height={17.58} color="graphene" mr={5}>
        {number}
      </Typography>
      <Typography font="400" size={15} height={21} color="gray">
        {label}
      </Typography>
    </Flex>
  );
};

export default Follow;
